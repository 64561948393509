function initLightBox() {
	const lightBox = document.querySelector('.lightbox');
	const images = document.querySelectorAll('.projet-image');
	const closeBtn = document.querySelector('.lightbox-close');
	const nextBtn = document.querySelector('.lightbox-next');
	const prevBtn = document.querySelector('.lightbox-prev');
	const lightBoxImage = document.querySelector('.lightbox-image img');
	let activeImageIndex = null;

	for (let i = 0; i < images.length; i++) {
		images[i].addEventListener('click', function(e) {
			e.preventDefault();
			activeImageIndex = i;
			show(images[i].href);
		});
	}

	closeBtn.addEventListener('click', hide);
	nextBtn.addEventListener('click', next);
	prevBtn.addEventListener('click', prev);

	function show(img) {
		lightBoxImage.src = img;
		lightBox.classList.add('active');
	}

	function hide() {
		lightBoxImage.src = "";
		lightBox.classList.remove('active');
	}

	function next() {
		activeImageIndex = activeImageIndex < images.length - 1 ? activeImageIndex + 1 : 0; 
		lightBoxImage.src = images[activeImageIndex].href;
	}

	function prev() {
		activeImageIndex = activeImageIndex > 0 ? activeImageIndex - 1 : images.length - 1;
		const prevImage = images[activeImageIndex];
		lightBoxImage.src = prevImage.href;
	}
}

function isotopeArticles() {
	var container = $('.articles');
	
	var isoOptions = {
		itemSelector: '.article',
		transitionDuration: 0,
		masonry: {
			columnWidth: '.article',
			gutter: 30
		}
	};

	window.addEventListener('resize:end', function(event) {
		container.isotope(isoOptions);
	}, false);

	container.isotope(isoOptions);
}


function isotopeProjects() {
	var container = $('.projets-list');
	
	var isoOptions = {
		itemSelector: '.projets-list-item',
		transitionDuration: 0,
		masonry: {
			columnWidth: '.projets-list-item',
			gutter: 30
		}
	};

	window.addEventListener('resize:end', function(event) {
		container.isotope(isoOptions);
	}, false);

	container.isotope(isoOptions);
};


function isotopeMotsBalises() {
	var container = $('.mots_balises');
	
	var isoOptions = {
		itemSelector: '.mots_balises-item',
		transitionDuration: 0,
		masonry: {
			columnWidth: '.mots_balises-item-sizer',
			gutter: '.mots_balises-gutter-sizer'
		}
	};

	window.addEventListener('resize:end', function(event) {
		container.isotope(isoOptions);
		fixLetterNavHeight();
	}, false);

	// fires on first page load
	container.isotope(isoOptions);
	scrollSpyLetters();
};



/* Mots Balises */

var fixLetterNavHeight = function(){
	$('.mots_balises-nav-wrapper').css('height', $('.mots_balises-nav ul').outerHeight(true));
};

var toggleActiveLetter = function(letter){
	$('.mots_balises-nav-lettre').removeClass('active');
	if(letter === 'lettre-x-y-z'){
		$('#nav-lettre-x').addClass('active');  
		$('#nav-lettre-y').addClass('active');  
		$('#nav-lettre-z').addClass('active');  
	}
	else{
		$('#nav-' + letter).addClass('active');  
	}
};

function scrollSpyLetters() {
	$('.mots_balises-nav').scrollspy({
		min: function () { 
			return ($('.mots_balises').offset().top);
		},
		max: function () { 
			return ($('.mots_balises').height() + $('.mots_balises').offset().top - $('.mots_balises-nav').outerHeight(true));
		},
		onEnter: function(element, position) {
			$('.mots_balises-nav').addClass('fixed').removeClass('bottom');
		},
		onLeave: function(element, position) {
				if(position.top <= $('.mots_balises').offset().top){ // leaves on top
					$('.mots_balises-nav').removeClass('fixed bottom');
				}
				else{ // leaves on bottom
					$('.mots_balises-nav').removeClass('fixed').addClass('bottom');
					fixLetterNavHeight();
				}
			}
		});

	$('.mots_balises-item-big-letter').each(function() {
		var el = $(this);
		el.scrollspy({
			min: function () { 
				return (el.position().top);
			},
			max: function () { 
				return (el.position().top + el.height());
			},
			onEnter: function(element, position) {
				toggleActiveLetter(element.id);
			}
		});
	});

	$(window).on('hashchange', function() {
		var letter = window.location.hash.substring(1);
		if(letter === 'lettre-x-y-z'){
			toggleActiveLetter(letter);
		}
		else if($('.mots_balises-nav-lettre#nav-' + letter).length > 0){
			toggleActiveLetter(letter);
		}
	});

};


/* Archives accordion */

if (document.querySelector('.archives')) {
	initArchivesAccordion();
}
function initArchivesAccordion() {
	const archivesYears = document.querySelectorAll('.archives-year');

	for (const archivesYear of archivesYears) {
		archivesYear.addEventListener('click', function() {
			const list = archivesYear.nextElementSibling;
			toggleArchive(list);
		});
	}
	// Toggle first list
	toggleArchive(archivesYears[0].nextElementSibling);

	function toggleArchive(el) {
		const height = el.scrollHeight;
		if (el.style.maxHeight === "0px" || !el.style.maxHeight) {
			el.style.maxHeight = height + "px";
		} else {
			el.style.maxHeight = "0px";
		}
	}
}


/* Header */

const shapeBlue = document.querySelector('.shape-blue');
const shapeGreen = document.querySelector('.shape-green');
const shapeYellow = document.querySelector('.shape-yellow');
const shapeBrown = document.querySelector('.shape-brown');

const shapeBluePositions = [
{
	viewport: 699,
	left: -337,
	top: -58,
	width: 977,
	height: 223
},
{
	viewport: 700,
	left: -640,
	top: -166,
	width: 1868,
	height: 426
},
{
	viewport: 1280,
	left: -640,
	top: -166,
	width: 1868,
	height: 426
},
{
	viewport: 2500,
	left: -830,
	top: -166,
	width: 1868,
	height: 426
}
];

const shapeGreenPositions = [
{
	viewport: 699,
	right: -162,
	top: -14,
	width: 406
},
{
	viewport: 700,
	right: -262,
	top: -96,
	width: 765
},
{
	viewport: 1280,
	right: -262,
	top: -96,
	width: 765
},
{
	viewport: 2500,
	right: -270,
	top: -96,
	width: 765
}
];


const shapeYellowPositions = [
{
	viewport: 699,
	left: 150,
	top: -108,
	width: 574,
	height: 310,
},
{
	viewport: 700,
	left: 276,
	top: -2,
	width: 765,
	height: 197
},
{
	viewport: 1280,
	left: 400,
	top: -2,
	width: 765,
	height: 197
},
{
	viewport: 2500,
	left: 340,
	top: -2,
	width: 765,
	height: 197
},
];

const shapeBrownPositions = [
{
	viewport: 699,
	right: -574,
	top: -2,
	width: 574
},
{
	viewport: 700,
	right: -800,
	top: -52,
	width: 815
},
{
	viewport: 1280,
	right: -650,
	top: -52,
	width: 815
},
{
	viewport: 2500,
	right: -770,
	top: -52,
	width: 824
},
];

updateShapes();

window.addEventListener('resize', function(e) {
	updateShapes();
});

function updateShapes() {
	updateShape(shapeBlue, window.innerWidth, shapeBluePositions);
	updateShape(shapeGreen, window.innerWidth, shapeGreenPositions);
	updateShape(shapeYellow, window.innerWidth, shapeYellowPositions);
	updateShape(shapeBrown, window.innerWidth, shapeBrownPositions);
}

function updateShape(shape, viewport, positions) {
	const shapePos = shapePositionFor(viewport, positions);
	shape.style.left = `${shapePos.left}px`;
	shape.style.right = `${shapePos.right}px`;
	shape.style.top = `${shapePos.top}px`;
	shape.style.width = `${shapePos.width}px`;
	shape.style.height = `${shapePos.height}px`;
}

function shapePositionFor(viewport, positions) {
	let posA, posB, t, left, right, top, width, height;
	// Find the two closest breakpoints
	posA = positions[0];
	posB = positions[0];

	for (let i = 0; i < positions.length; i++) {
		const pos = positions[i];

		if (pos.viewport <= viewport && pos.viewport >= posA.viewport) {
			posA = pos;
			if (positions[i+1]) {
				posB = positions[i+1];
			} else {
				posB = pos;
			}
		}
	}

	// Calculate t parameter for interpolation
	if (viewport < posA.viewport) {
		t = 0;
	} else if (viewport >= posB.viewport) {
		t = 1;
	} else {
		t = (viewport - posA.viewport) / (posB.viewport - posA.viewport);
	}

	// Calculate coordinates
	left = lerp(posA.left, posB.left, t);
	right = lerp(posA.right, posB.right, t);
	top = lerp(posA.top, posB.top, t);
	width = lerp(posA.width, posB.width, t);
	height = lerp(posA.height, posB.height, t);

	return { left, right, top, width, height };
}

function lerp (a, b, t) {
	return (1-t)*a+t*b;
}


$('#subscribe-text').appendTo('.jetpack_subscription_widget form');
$('.jetpack_subscription_widget .error').appendTo('.jetpack_subscription_widget form');

// Masonry
isotopeArticles();
isotopeProjects();

if ($('body').hasClass('post-type-archive-mots_balises')) {
	isotopeMotsBalises();
}

if ($('body').hasClass('single-projets')) {
	initLightBox();
}

